import type { NavigationGuard } from 'vue-router';

import { useFrontendSettingsStore, useVpsServerStore } from '@/stores';
import { Route } from '@/types';

const hVpsManagementGuard: NavigationGuard = async (to, from, next) => {
  const serverStore = useVpsServerStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  const isServerLoaded = !!serverStore.servers.length;

  if (!isServerLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await serverStore.fetchServers();
    frontendSettingsStore.setState('pageLoading', false);
  }

  const serverId = Number(to.params.id);

  if (!serverStore.getServerById(serverId)) {
    return next({ name: Route.Base.SERVERS });
  }

  serverStore.setManagedId(serverId);

  return next();
};

export default hVpsManagementGuard;
